import useWindowDimensions from "../../hooks/windowsWidth";

export default function Button({ title, handleClick, textSize = "39.74px", connect, smallFont, greenButton }) {
  const { width } = useWindowDimensions();

  return (
    <div className="min-h-16 max-w-64 bg-[#928B8C] border-l-[3.5px] border-b-[3.5px] border-r-[2px] border-[#2D1616] drop-shadow-xl shadow-[#00000050] shadow-md rounded-full px-3 py-3">
      <div className={`min-h-16 max-w-56 ${greenButton ? "border-l-[6.5px] border-b-[6px] bg-[#568132] hover:bg-[#90BA5F] border-l-[#568132] hover:border-l-[#90BA5F] border-b-[#568132] hover:border-b-[#90BA5F]" : (connect ? 'hover:bg-[#4B1321] bg-[#DFB245] hover:border-l-[#4B1321] border-l-[#DFB245] hover:border-b-[#4B1321] border-b-[#DFB245] border-l-[6px] border-b-[5px]' : ' border-l-[6px] border-b-[5px] bg-[#4B1321] hover:bg-[#DFB245] border-l-[#4B1321] hover:border-l-[#DFB245] border-b-[#4B1321] hover:border-b-[#DFB245]')} rounded-full -mt-5`}>
        <button  onClick={handleClick} style={{ fontSize: width < 1300 && smallFont ? smallFont : textSize }} className={`min-h-16 max-w-56 font-black ${greenButton ? "font-outline-2 text-[#1A280B]  bg-[#90BA5F] hover:bg-[#C4EC94] border-r-[4px] border-t-[4px] border-t-[#769F4F]  hover:border-t-white border-r-[#769F4F] hover:border-r-white" : (connect ? 'hover:border-t-[#CF899A]  border-t-white hover:border-r-[#CF899A] border-r-white hover:bg-[#8D1431] bg-[#FFF5DD] hover:text-white text-[#2D1616]' : ' border-t-[#CF899A] text-white hover:text-[#2D1616]  hover:border-t-white border-r-[#CF899A] hover:border-r-white bg-[#8D1431] hover:bg-[#FFF5DD]')} border-r-[4px] border-t-[4px] rounded-full z-10 px-6 py-1.5`}>
          {title}
        </button>
      </div>
    </div>
  );
}
