import React from "react";
import GameInfo from "../subcomponents/GameInfo";

export default function MbGame() {
  const handleClick = () => {
    window.location.href = "/pdf-file/game-deck.pdf";
  };
  return (
    <div
      className="flex flex-col items-center justify-center pb-20 bg-no-repeat bg-cover gap-y-2 "
      style={{ backgroundImage: "url(/mobile-images/mb-bg-rojo.svg)" }}
    >
      <div className="-mt-8">
        <img src="/mobile-images/coin.svg" alt="coin" />
      </div>
      <div className="bg-[#FFF5DD] bg-opacity-70 custom-shadow w-full flex flex-col text-[64.77px] leading-[54px]  text-[#350914] font-black  py-4 text-shadow  uppercase">
        {" "}
        <span className="text-[53.77px] leading-[44px]">The </span> Game
      </div>
      <div className="p-2">
        <div
          className="pt-4 pl-2 bg-no-repeat bg-cover "
          style={{ backgroundImage: "url(/mobile-images/mix-blend.svg)" }}
        >
          <GameInfo handleClick={handleClick} />
        </div>
      </div>
    </div>
  );
}
