import React from 'react';
import Button from '../subcomponents/Button';

export default function Topbar({ aboutRef, gameRef, roadmapRef, teamRef }) {
  const handleScroll = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  // Destructure necessary values from the SDK hook

  const connect = async () => {
   
  }

  return (
    <div className='w-full flex px-10 items-center h-24'>
      <div className='w-1/5'>
        <img src='/images/logo.svg' alt='logo' className='min-h-[120px] w-full' />
      </div>

      <div className='w-3/5 flex justify-center'>
        <div><Button title={"About"} handleClick={() => handleScroll(aboutRef)} textSize='22.5px' smallFont={'16px'} /></div>
        <div><Button title={"Game"} handleClick={() => handleScroll(gameRef)} textSize='22.5px' smallFont={'16px'} /></div>
        <div><Button title={"Roadmap"} handleClick={() => handleScroll(roadmapRef)} textSize='22.5px' smallFont={'16px'} /></div>
        <div><Button title={"Team"} handleClick={() => handleScroll(teamRef)} textSize='22.5px' smallFont={'16px'} /></div>
      </div>

      <div className='w-1/5 flex justify-end'>
        <div>
          {/* Button for connecting to MetaMask */}
          <Button title={"Connect"} handleClick={connect} textSize='22.5px' smallFont={'16px'} />

          {/* Display connected chain and account if connected */}
          {/* {connected && (
            <div>
              <>
                {chainId && `Connected chain: ${chainId}`}
                <p></p>
                {account && `Connected account: ${account}`}
              </>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}
