
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import WebRouter from './routes';

function App() {

  return (
    <div className="App ">
     <BrowserRouter>
          <WebRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
