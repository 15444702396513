import React from "react";

export default function MbHero() {
  return (
    <div
      className="flex  mb-hero-bg"
    >
       <div className=' self-start p-6'>
        <img src='/images/mb-logo.svg' alt='logo' className='' />
      </div>
      {/* <span className="mb-text text-[#2D1616] text-xl leading-[23.8px] font-bold w-full">
        Danuki is a web3 roguelite,
        <br /> Castlevania-inspired
        <br /> action-platformer building <br /> on <b className="font-black">BEAM</b>
      </span> */}
    </div>
  );
}
