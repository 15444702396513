import React from "react";
import TeamCard from "../subcomponents/TeamCard";
import { teamData } from "../../data/teamData";

export default function Team() {


  return (
    <div
      className="w-full  pt-20 flex flex-col gap-y-20 pb-20 team-bg "
  
    >
      <div className="flex flex-col items-center bg-[#FFF5DD] bg-opacity-70 py-4 ">
        <span className="text-[70px] leading-10  text-[#131924] font-black  py-4 custom-shadow">
          TEAM
        </span>
        <img
          src="/images/line-pedorra.svg"
          alt="line"
          className="w-[100%] h-[20px] "
        />
      </div>
  <div className="flex items-center justify-center w-full"> 
        <div className="flex gap-x-4 2xl:gap-x-10 xl:w-[88%] 2xl:w-[80%] ">
          {teamData.map((item, index) => (
            <TeamCard
              imageUrl={item.imageUrl}
              title={item.title}
              text={item.text}
              description={item.description}
              key={index}
            />
          ))}
   </div>
      </div>
    </div>
  );
}
