import React, { useEffect } from "react";
import Hero from "../components/desktopComponents/Hero";
import About from "../components/desktopComponents/About";
import Danukis from "../components/desktopComponents/Danukis";
import Game from "../components/desktopComponents/Game";
import RoadMap from "../components/desktopComponents/RoadMap";
import Team from "../components/desktopComponents/Team";
import Footer from "../components/desktopComponents/Footer";
import { ModalProvider } from "react-simple-modal-provider";
import MintModal from "./modal/MintModal";

export default function DesktopPage({
  heroRef,
  aboutRef,
  gameRef,
  roadmapRef,
  teamRef,
}) {

  useEffect(() => {
    const listenToScroll = () => {
      console.log("scrolling");
      const winScroll = document.body.scrollTop ||
        document.documentElement.scrollTop;
      console.log(winScroll);
    };

    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  return (
    <ModalProvider value={[MintModal]}>
    <div>
      <div ref={heroRef}>
        <Hero
          aboutRef={aboutRef}
          gameRef={gameRef}
          roadmapRef={roadmapRef}
          teamRef={teamRef}
        />
      </div>

      <div ref={aboutRef}>
        <About />
      </div>

      <Danukis />

      <div ref={gameRef}>
        <Game />
      </div>

      <div ref={roadmapRef}>
        <RoadMap />
      </div>

      <div ref={teamRef}>
        <Team />
      </div>

      <Footer heroRef={heroRef} />
    </div>
    </ModalProvider>
  );
}
