import React from "react";
import Button from "./Button";

export default function GameInfo({ handleClick }) {
  return (
    <div className="flex flex-col lg:flex-row lg:justify-between lg:w-11/12 ">
      {" "}
      <div className="flex px-10 py-5 lg:w-2/3 ">
        <ul className=" text-white  list-disc text-left text-xl xl:text-2xl 2xl:text-3xl leading-[23.4px] lg:leading-[40px] font-semibold flex flex-col gap-y-4">
          <li className="py-2">
            You are the <b>chosen one</b>, destined to combat evil and liberate <b>Yokodana.</b>
          </li>
          <li className="py-2">
            <b> Play for fun. </b> Enhance the value of your <b>Danuki NFT</b>{" "}
            through in-game permanent unlocks, level-ups, and upgrades.
          </li>
          <li className="py-2">
            <b>Earn rewards</b> by climbing the leader board,{" "}
            <b>betting in PvP </b>with
            <b> GambleFi</b> features and <b>in-game challenges.</b>
          </li>
          <li className="py-2">
            <b> Experience the excitement </b>of new lore chapter releases.
          </li>
        </ul>
      </div>
      <div className="flex flex-col items-center lg:-mt-20">
        <img
          src="/images/game-deck.svg"
          alt=""
          className="w-11/12"
        />

        <a className=""  href="https://docsend.com/view/qh7w8fnvynkbtv6t " target="blank" download={"DANUKI GAME DECK.pdf"}>
          <Button title={"Download"} textSize="27px" />
        </a>
      </div>
    </div>
  );
}
