import React, { useRef } from "react";
import DesktopPage from "../components/DesktopPage";
import MbPage from "../components/MbPage";
export default function HomePage() {

  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const gameRef = useRef(null);
  const roadmapRef = useRef(null);
  const teamRef = useRef(null);

  return (
    <div className=" font-nunito max-w-[1800px] shadow-xl">
      <div className="hidden lg:flex " >
        <DesktopPage aboutRef={aboutRef} gameRef={gameRef} heroRef={heroRef} roadmapRef={roadmapRef} teamRef={teamRef}   />
      </div>
      <div className=" lg:hidden">
        <MbPage heroRef={heroRef}/>
      </div>
    </div>
  );
}
