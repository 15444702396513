import React from "react";
import Button from "../subcomponents/Button";

export default function Footer({ heroRef }) {
  const scrollToHero = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const email = "danuki@danuki.io";
  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`
  };


  return (
    <div className=" bg-gradient-to-b from-[#060504]  via-[#2D161D] via-[21%] to-[#CF899A] px-12 pt-16 pb-3">
      <div className="flex items-center justify-center text-6xl gap-x-4 text-white opacity-[69%]">
        {/* <a href="https://discord.gg/WGh67Cgb" target="_blank" >
          <FaDiscord />
        </a>
        <a href="https://x.com/danukikintama" target="_blank">
          <FaTwitter />
        </a> */}
        <a href="https://linktr.ee/danuki" target="_blank" rel="noreferrer" >
          <img className="w-12" src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/linktree-white-icon.png" alt="socials" />
        </a>
      </div>
      <div className="flex items-end justify-between text-white">
        <div className="">
          <img
            src=" /images/orange-danuki.svg"
            alt="logo"
            className="min-h-[100px] w-[320px]"
          />
        </div>
        <div className=" text-2xl flex flex-col text-center leading-10 pt-10 -ml-28">
          <span className="font-bold ">Reach us</span>
          <span className="cursor-pointer" onClick={handleEmailClick}>{email}</span>
        </div>
        <div>
          <Button title={"Home"} handleClick={scrollToHero} footer />
        </div>
      </div>
      <div className=" py-4 font-semibold text-[30px] text-white">
        Danuki Kintama all rights reserved 2024
      </div>
    </div>
  );
}
