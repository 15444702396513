import React from 'react'
import { useRoutes } from 'react-router-dom'
import HomePage from '../pages/HomePage';



export default function WebRouter() {
  let routes = useRoutes ([
    {path: "/", element: <HomePage/>},]);
    return  routes;
}