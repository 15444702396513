import useWindowDimensions from "../../hooks/windowsWidth";

export default function MintNowButton({title , handleClick, textSize="39.74px", connect, smallFont ,  greenButton}) {
  const { width } = useWindowDimensions();

  console.log(width)

  return (
    <div className="min-h-16 max-w-64  bg-[#928B8C] border-l-[3.5px] border-b-[3.5px] border-r-[2px] border-[#2D1616] drop-shadow-xl shadow-[#00000050] shadow-md rounded-full px-3 py-3">
      <div className={`min-h-16 max-w-56  border-l-[6px] border-b-[6px] bg-[#568132] hover:bg-[#90BA5F] border-l-[#568132] hover:border-l-[#90BA5F] border-b-[#568132] hover:border-b-[#90BA5F]  rounded-full -mt-5   `} >
        <button style={{fontSize: (width < 1300 && smallFont) ? smallFont: textSize }} className={`min-h-16 max-w-56 text-stroke  font-black font-outline-2 text-[#1A280B ]  bg-[#90BA5F] hover:bg-[#C4EC94] border-r-[4px] border-t-[4px] border-t-[#769F4F]  hover:border-t-white border-r-[#769F4F] hover:border-r-white rounded-full z-10 px-6 py-1.5`} onClick={handleClick}>
         { title}
        </button>
      </div>
    </div>
  );
}
