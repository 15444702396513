import React from "react";
import Button from "../subcomponents/Button";

export default function MbFooter({ heroRef }) {
  const handleClick = () => {
    if (heroRef && heroRef.current) {
      heroRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const email = "danuki@danuki.io";
  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`
  };

  return (
    <div className="flex flex-col px-6 items-center pt-16 pb-6 gap-y-10 bg-gradient-to-b from-[#060504]  via-[#2D161D] via-[21%] to-[#CF899A] ">
      <div className="flex items-center justify-center text-6xl gap-x-4 text-white opacity-[69%]">
        {" "}
        {/* <a href="https://discord.gg/WGh67Cgb"> <FaDiscord /></a>   <a href="https://x.com/danukikintama">    <FaTwitter /></a>   {" "} */}
        <a href="https://linktr.ee/danuki" target="_blank" rel="noreferrer" >
          <img className="w-12" src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/linktree-white-icon.png" alt="socials" />
        </a>
      </div> 

      <div className="py-16">
        <img src=" /images/orange-danuki.svg" alt="logo" className="" />
      </div>

      <div>
        <Button title={"Home"} handleClick={handleClick} />
      </div> 
      <div>
        <div className="text-white text-sm flex flex-col text-center  ">
          <span className="font-bold ">Reach us</span>
          <span onClick={handleEmailClick}>{email}</span>
        </div>
        <div className=" py-4 font-semibold text-white">
          Danuki Kintama all rights reserved 2024
        </div>
      </div>
    </div>
  );
}
