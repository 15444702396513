import React, { useEffect } from "react";
import MbHero from "./mobileComponents/MbHero";
import Connect from "./mobileComponents/Connect";
import MbAbout from "./mobileComponents/MbAbout";
import MbDanuki from "./mobileComponents/MbDanuki";
import MbGame from "./mobileComponents/MbGame";
import MbTeam from "./mobileComponents/MbTeam";
import MbFooter from "./mobileComponents/MbFooter";
import MbRoadMap from "./mobileComponents/MbRoadMap";

export default function MbPage({ heroRef }) {
  useEffect(() => {
    const listenToScroll = () => {
      console.log("scrolling");
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      console.log(winScroll);
    };

    window.addEventListener("scroll", listenToScroll);
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);
  return (
    <div className="">
      <div ref={heroRef}>
        <MbHero />
      </div>
      <Connect />
      <MbAbout />
      <MbDanuki />
      <MbGame />
      <MbRoadMap />
      <MbTeam />
      <MbFooter heroRef={heroRef} />
    </div>
  );
}
