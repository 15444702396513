import React from "react";
import Button from "../subcomponents/Button";

export default function Connect() {
    const handleClick = () => {
       console.log("Connect button clicked")
    }
  return (
    <div
      className="flex flex-col items-center bg-cover py-10 px-4 gap-y-4 bg-no-repeat "
      style={{ backgroundImage: "url(/mobile-images/rosa.svg)" }}
    >
      <Button title={"Connect"} handleClick={handleClick} greenButton={true}/>
      <span className="text-[#1A280B] font-black text-2xl leading-[21.8px] font-outline-2">
        Price: TBA
      </span>
    </div>
  );
}
