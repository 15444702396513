export const teamData = [
    {
        imageUrl: "/images/art-director.svg",
        title: "MoonWhoArt",
        text: "CEO / Art Director",
        description:"Professionals artist with 10+ years of experience,digital illustration specialist,the original Danuki creator."
      },
      {
        imageUrl: "/images/sun-who.svg",
        title: "SunWho",
        text: "Art Sub-director/Advisor",
        description:"Zero365 co-founder. certified meme doctor, community cult specialist, CT characters creator, cryptobro since 2014, BEAM enthusiast, ethereal entity."
      },
      {
        imageUrl: "/images/roubrna.svg",
        title: "ROBURNA Labs",
        text: " Development",
        description:"Bringing Web3 and Web2 ideas to life since 2017, harnessing advanced technology while also prioritizing environmental preservation."
      },
      {
        imageUrl: "/images/winsome.svg",
        title: "Winsome ",
        text: "CMO",
        description:"Zero365 co-founder. Supported projects such as Injective, Marlin, Coldstack and Symbiosis Finance. Experienced project manager with 25 years of experience in IT, bringing balance in the most professional ways. Team's favorite boomer."
      },
      {
        imageUrl: "/images/wolf.svg",
        title: "Wolf ",
        text: "CPO",
        description:"Marketing and Human Resources Specialist, Martial Artist with 15+ years of experience, Japanese Culture connoisseur, howls occasionally, doesn't bite."
      },

]