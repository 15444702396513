import React from 'react'

export default function TeamCard({imageUrl,title,text,description}) {
  return (
    <div className='flex flex-col w-full items-center rounded-b-[60px] bg-[#151E25]  px-3 pt-5 pb-8 gap-y-4 card-shadow'>
       <img src={imageUrl} alt='art' className='w-full xl:min-h-[180px] lg:min-h-[200px] flex items-center justify-center  '/> 
       <div className='flex flex-col gap-y-4'>
        <div className='flex flex-col min-h-10'>
        <span className='text-white text-[13px] xl:text-lg 2xl:text-[24px]'>{title}</span>
       <span className='text-[#E36E42] text-[9px] 2xl:text-[17px] lg:text-sm'>{text}</span></div>
       <span className='text-white text-[9px] font-normal text-left text-xs lg:text-sm  lg:px-6 xl:px-4'>{description}</span>
       
       </div>
    </div>
  )
}
