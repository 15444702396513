import React from 'react'

export default function MbDanuki() {
  return (
    <div
          className="z-10 -mt-2 bg-cover pb-12 bg-no-repeat"
          style={{backgroundImage:"url(/mobile-images/mb-textura-verde.svg)"}}
        >
          <div className="">
          <div className='flex flex-col px-10 '>  <img src="/images/10.000-danukis.svg" alt="Danukis" className=" -mt-6 h-12" />
            <img src="/mobile-images/vs-endless-evil.svg" alt="testicles" className=""  />
            </div> <span className="uppercase text-white font-black text-[27.5px] leading-[25.5px] ">
              SLASH AND DASH 'TIL THE DARKNESS FALLS!
            </span>
            <img src="/images/linea-orange.svg" alt="line" className=""/>
          </div>
          <div className="py-8" >
            <img src="/images/collage.svg" alt="collage" className="   "  />
            <img src="/mobile-images/game.svg" alt="game" className="" />
          </div>
        </div>
  
  )
}
