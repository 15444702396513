import React from 'react'

export default function MbRoadMap() {
  return (

        <div className="grid grid-flow-row items-center py-8 bg-cover bg-np-repeat " style={{backgroundImage:"url(/mobile-images/mb-textura-roja.svg)"}} >
          <div className="flex flex-col items-center gap-y-4 px-6  text-center">
            <span className=" text-white text-[56px]  font-extrabold uppercase">
              Roadmap
            </span>
            <img src="/images/white-thick-line.svg" alt="thick-line" className="-mt-4 " />
            <img src="/images/white-thin-line.svg" alt="thin-line" className="" />
          </div>
          <div className="flex  px-6">
            <img src="/mobile-images/mb-map.svg" alt="roadmap" className="w-full " />
          </div>
        </div>
      );
    }
