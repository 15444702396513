import React from "react";

export default function MbAbout() {
  return (
    <div className="py-10 mb-about-bg ">
      <div className="flex flex-col items-center px-6 py-10 ">
        <span className="uppercase font-black text-[45px] leading-[38px] text-[#FFF5DD] text-outline">ABOUT</span>
        <img src="/images/sm-orangeline.svg" alt="line" className="w-60 " />
      </div>
      <div
        className="flex flex-col items-center pt-10 ml-2 pb-36 mb-black-bg "
      >
        <div className="flex flex-col text-lg font-semibold text-white gap-y-4 ">
          <span className="px-10 text-[19px]"> Reawakened in 2024, our mission is to make Danuki {" "}
            <b className="font-black uppercase">
              {" "}
              The best game of its kind.{" "}
            </b>{" "}</span>
          <span className="px-8">
            {" "}
            Bringing <b className="font-black">cuteness </b>and{" "}
            <b className="font-black">violence</b> made with love and dedication
            by
            <em ><b> enslaved artists</b></em> in our basement.
          </span>{" "}
          <span className="px-6">
            {" "}
            Fueled by{" "}
            <b className="font-black ">
              the sweat from the threat of permadeath roguelite style
            </b>{" "}
            and the {" "}
            <b className="font-black"> tears of PvP players</b> in
            GambleFi, resulting in <b className="font-black">the best of web3 games rarely seen <br />before; <br /></b> "P4F"{" "}
            <b className="font-black uppercase">(play for fun)</b>.
          </span>
        </div>
        <div>   <img src="/mobile-images/peach.svg" alt="peaches" />
        </div>
      </div>

      <div className="z-10 px-6 -mt-32">
        <img src="/images/morfeo.svg" alt="" />
      </div>
    </div>
  );
}
