import React from "react";

export default function RoadMap() {
  return (
    <div className="grid grid-flow-row items-center py-8 road-map-bg " >
      <div className="flex flex-col items-center lg:gap-y-4 2xl:gap-y-6 text-center">
        <span className=" text-white  lg:text-[70px] 2xl:text-[100px] font-extrabold uppercase custom-shadow leading-10 2xl:mt-4">
          Roadmap
        </span>
        <img src="/images/white-thick-line.svg" alt="thick-line" className="w-[40%]  mt-1 2xl:mt-4" />
        <img src="/images/white-thin-line.svg" alt="thin-line" className="w-[30%] -mt-2 " />
      </div>
      <div className="hidden lg:flex px-10">
        <img src="/images/map.svg" alt="roadmap" className="w-full " />
      </div>
    </div>
  );
}
