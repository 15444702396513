import React from "react";
import Modal, { useModalState } from "react-simple-modal-provider";
import { RxCross2 } from "react-icons/rx";

export default function MintModal({children}) {
    const [isOpen, setOpen] = useModalState();

    const closeModal = () => {
        setOpen(false);
    }


  return (
    <Modal
      id={"mint-modal"}
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
    >
     <div className="modal-bg rounded-md p-28 flex items-center justify-center ">
     <div className="text-3xl text-white flex  justify-end absolute top-4 right-4">
     <RxCross2 onClick={closeModal} className="cursor-pointer" />  
      </div>
     <span className="text-white text-[44px] italic font-extrabold">Coming Soon Stay Tuned</span>
     </div> 
    </Modal>
  );
}
