import React from "react";

export default function About() {
  return (
    <div
      className="flex items-start justify-end py-10 pl-10 about-bg "
    >

      <div className="flex flex-col items-end w-[55%] h-full ">

        <div className="flex flex-col items-center w-full about-section">
          <img
            src="/images/about.png"
            alt="Danukis"
            className=" h-[100px] w-[300px]"
          />
          <img
            src="/images/sm-orangeline.svg"
            alt="line"
            className="-mt-2 w-[360px]"
          />
        </div>
        <div className="flex flex-col h-[620px] items-ends justify-center w-full">
          <div className="flex items-center w-full h-full ">
            <img src="/images/leaves.svg" alt="leaves" className="absolute z-10 lg:w-[160px] lg:-mt-[10%] xl:-mt-[5%] 2xl:-mt-[5%] xl:w-[200px] " />
          </div>
          <div className="absolute bg-about-rectangle h-[570px] w-full  mix-blend-multiply z-0">

          </div>
          <div className="flex flex-col relative z-10 gap-y-5 text-white font-semibold lg:text-[base] xl:text-[24px] 2xl:text-[30px]  px-10 xl:leading-[40px] lg:leading-[32px] ">
            <div >
              <span className="leading-5 "> Reawakened in 2024, our mission is to make Danuki </span>
              <span className="mt-2 font-black leading-7 uppercase "> The best game of its kind. </span>
            </div>
            <div>
              <span>
                Bringing <span className="font-black leading-5 "> cuteness</span> and <span className="font-black "> violence </span> made with love and dedication by
                <span className="italic font-bold ">  enslaved artists </span> in our basement.
              </span>
            </div>
            <div>
              <span>
                Fueled by <span className="font-black ">  the sweat from the threat of permadeath roguelite style   </span>and the
                <span className="font-black "> tears of PvP players,</span> resulting in <span className="font-black "> the best of
                  web3 games, rarely seen before; <br /> </span> "P4F" <span className="font-black uppercase"> (play for fun)</span>.
              </span>
            </div>

          </div>
          <div className="flex items-end justify-end w-full h-full ">
            <img src="/images/badge.svg" alt="leaves" className="absolute z-10 lg:w-[120px] xl:w-[160px] " />
          </div>

        </div>

      </div>
    </div>
  );
}
