import React from "react";

export default function Danukis() {
  return (
    <div
      className="grid grid-flow-row  danukis-bg"
    >
      <div className="shadow flex flex-col items-center text-center custom-shadow px-10 lg:-mt-12 ">
     <span className=" danukis-text font-black lg:text-[70px] 2xl:text-[90px] drop-shadow-xl   z-10 leading-tight">10.000 Danukis</span>
     <span className="danukis-text font-black lg:text-[80px] 2xl:text-[103px]  drop-shadow-xl leading-tight">vs endless evil</span>
     
        {/* <img src="/images/10.000-danukis.svg" alt="Danukis" className="xl:-mt-20 lg:-mt-16 z-10 lg:h-[80px] xl:h-[36%] " />
        <img src="/images/20.000-testicles.svg" alt="testicles" className="lg:h-[70px] xl:h-[30%]"  /> */}
        <span className="uppercase text-white font-black lg:text-[40px] xl:text-[50px]  2xl:text-[64px] pt-2 ">
          SLASH AND DASH 'TIL THE DARKNESS FALLS!
        </span>
        <img src="/images/linea-orange.svg" alt="line" className=""/>
      </div>
      <div className="flex justify-between items-start overflow-hidden " >
        <div className="w-[40%] ">
        <img src="/images/collage.svg" alt="collage" className="pl-8 pt-4 w-full "  />
        </div>
        <div className="w-[60%]">
        <img src="/images/fight-game.svg" alt="game" className=" w-full" />
        </div>
      </div>
    </div>
  );
}
