import React from "react";
import TeamCard from "../subcomponents/TeamCard";
import { teamData } from "../../data/teamData";

export default function MbTeam() {

  return (
    <div
      className="bg-cover bg-no-repeat py-16"
      style={{ backgroundImage: "url(/mobile-images/mb-bg-rosa.svg)" }}
    >
      <div className="custom-shadow bg-[#FFF5DD] bg-opacity-70 w-full flex flex-col text-[64.77px] leading-[54px]  text-[#350914] font-black  py-4 text-shadow  uppercase">
        {" "}
        <span>Team</span>
        <img
          src="/images/line-pedorra.svg"
          alt="line"
          className="w-[100%] h-[20px] "
        />
      </div>
      <div className="flex items-center justify-center w-full px-8 gap-x-10 pt-10">
        <div className="grid grid-cols-1 gap-y-10 w-1/2 ">
          {teamData
            .filter((_, index) => index !== 1 && index !== 3)
            .map((item, index) => (
              <TeamCard
                imageUrl={item.imageUrl}
                title={item.title}
                description={item.description}
                text={item.text}
                key={index}
              />
            ))}
        </div>
        <div className="grid grid-cols-1 gap-y-10 w-1/2  ">
          {teamData
            .filter((_, index) => index !== 0 && index !== 2 && index !== 4)
            .map((item, index) => (
              <TeamCard
                imageUrl={item.imageUrl}
                title={item.title}
                description={item.description}
                text={item.text}
                key={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
