import React from "react";
import Topbar from "./TopBar";
import MintNowButton from "../subcomponents/MintNowButton";
import { useModal } from "react-simple-modal-provider";

export default function Hero({ aboutRef, gameRef, roadmapRef, teamRef }) {
  const { open: openModal } = useModal("mint-modal");
  return (
    <>    <div className="flex flex-col min-h-screen w-full p-8 hero-bg">
      <div className="flex w-full mt-[3%]">
        <Topbar
          aboutRef={aboutRef}
          gameRef={gameRef}
          roadmapRef={roadmapRef}
          teamRef={teamRef}
        />
      </div>
      <div className="flex h-full items-center justify-between">
        <div className="w-[60%]">
          <img src="/images/text-danuki.svg" alt="hero" className="w-full " />
        </div>
        <div className="flex flex-col items-center xl:-mt-48 lg:-mt-20 w-[40%] ">
         <div className="w-[55%] pt-8 py-10 bg-no-repeat flex items-center justify-center bg-cover" style={{backgroundImage:"url('/images/pergamino.svg')"}}>
          <div className="pl-6 mt-2  mix-blend-luminosity w-3/4 flex items-center justify-center	" >
          <video autoPlay loop muted className="w-full rounded-xl">
            <source src="/images/danuki.mp4" type="video/mp4" />
          </video>
          </div>
          </div>
          <div className="flex flex-col mt-6 ">
            <div className=" flex  justify-center">
              <MintNowButton title={"Mint"} handleClick={openModal} />
               </div>
           
            <div className="w-full flex  ">
              <div className=" flex justify-center ">
                <span className="lg:text-[30px] xl:text-[39px] text-[#1A280B] font-black font-outline-price">
                  Price: TBA
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
                 
    </>
  );
}
