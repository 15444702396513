import React from "react";
import GameInfo from "../subcomponents/GameInfo";

export default function Game() {

  return (
    <div
      className=" w-full py-14 grid grid-flow-row gap-y-14  game-bg"
    >
      <div className="grid grid-flow-row items-center bg-[#FFF5DD] bg-opacity-70 lg:h-24 py-4 ">
        <span className="text-[70px] leading-10  text-[#350914] font-black  py-4 custom-shadow  uppercase">
          {" "}
          The Game
        </span>
      </div>
      <div className="flex justify-center px-20 ">
     
        <div className=" bg-[#5b2828] absolute lg:h-[500px] xl:h-[600px] z-0 w-4/5 max-w-[1480px] mix-blend-multiply  rounded-[32px]	">
        
        </div>
        <div className="flex  justify-center  gap-x-10 relative z-10 py-2  w-11/12  ">
          <GameInfo />
        </div> 
      </div>
    </div>
  );
}
